<template>
  <div>
    <div class="text-center font-700" p="x-4" text="xl md:2xl black">
      {{ coninsActiveTab === BURN ? t('key.business.howtoearn.coins.title.burn') : t('key.business.howtoearn.coins.howToEarnTitle') }}
    </div>
    <div class="block pt-6 md:flex md:px-8 md:pt-8">
      <div v-for="item in HowToEarnOptions" :key="item.icon" px="8 md:4" pb="8 md:0" class="flex flex-1 items-center md:mx-4 md:block md:text-center">
        <div
          h="6"
          w="6"
          rounded="full"
          text="base center white"
          flex="shrink-0 "
          bg="[#1B1B1B]"
          font="500"
          leading="6"
          class="md:hidden"
        >
          {{ item.icon }}
        </div>
        <img h="12 md:28" m="x-5 md:b-10" class="flex-shrink-0" :src="item.image" />
        <div text="xs #363636 md:lg md:black" font="400">
          {{ item.description() }}
        </div>
      </div>
    </div>
    <div v-if="coninsActiveTab === BURN" px="8" justify-center md:flex md:pt-10px>
      <NuxtLink
        md:inline-block
        to="https://corporate.azgotrip.com/burn-program"
        target="_blank"
        :external="true"
        class="block rounded-2.5 bg-#F4F4F4 px-6 py-3 text-center text-lg text-black font-700 md:px-17px md:py-13.5px md:text-26px"
      >
        {{ t('key.how.to.earn.learn.more') }}
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import ImageHowToEarn1 from '@/assets/images/home/how_to_earn_1.png'
import ImageHowToEarn2 from '@/assets/images/home/how_to_earn_2.png'
import ImageHowToEarn3P from '@/assets/images/home/how_to_earn_3_p.png'
import ImageHowToEarn3B from '@/assets/images/home/how_to_earn_3_burn.png'

const { t } = useI18n()
const { coninsActiveTab } = useUseBurnCoins()
const BURN = E_CONINS_ACTIVE_TAB.BURN

const HowToEarnOptions = computed(() => [
  {
    icon: '1',
    image: ImageHowToEarn1,
    description: () => {
      return {
        [E_CONINS_ACTIVE_TAB.BURN]: t('key.business.howtoearn.coins.burnDesc1'),
        [E_CONINS_ACTIVE_TAB.EARN]: t('key.business.howtoearn.coins.earnDesc1'),
      }[coninsActiveTab.value]
    },
  },
  {
    icon: '2',
    image: ImageHowToEarn2,
    description: () => {
      return {
        [E_CONINS_ACTIVE_TAB.BURN]: t('key.business.howtoearn.coins.burnDesc2'),
        [E_CONINS_ACTIVE_TAB.EARN]: t('key.business.howtoearn.coins.earnDesc2'),
      }[coninsActiveTab.value]
    },
  },
  {
    icon: '3',
    image: {
      [E_CONINS_ACTIVE_TAB.BURN]: ImageHowToEarn3B,
      [E_CONINS_ACTIVE_TAB.EARN]: ImageHowToEarn3P,
    }[coninsActiveTab.value],
    description: () => {
      return {
        [E_CONINS_ACTIVE_TAB.BURN]: t('key.business.howtoearn.coins.burnDesc3'),
        [E_CONINS_ACTIVE_TAB.EARN]: t('key.business.howtoearn.coins.earnDesc3'),
      }[coninsActiveTab.value]
    },
  },
])
</script>
